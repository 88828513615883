import { InformationCircleIcon } from "@heroicons/react/20/solid";
import { IconCheck, IconClock } from "@tabler/icons-react";
import * as React from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getRandomElement } from "../../../../functions/getRandomElement";
import { getRelativeTime } from "../../../../functions/getRelativeTime";
import { useJobs } from "../../../../hooks/api/jobs/useJobs";
import { HeadbotRoute } from "../../../../services/Constants/HeadbotRoute";
import { SecondaryButtonLink } from "../../../atoms/ButtonLink/SecondaryButtonLink";
import { H4 } from "../../../atoms/H4/H4";
import { Spinner } from "../../../atoms/Spinner/Spinner";
import { Color } from "../../../layout/GlobalStyles/Color";
import { JobImage } from "../../../molecules/JobImage/JobImage";
import { Title } from "../../BuffPage/BuffPage.styles";
import { StyleSection } from "../../HomePage/StyleSection";
import { Content, ContentRoot, JobListItem, JobListRoot, Root } from "./Jobs.styles";

interface IProps {}
export const Jobs: React.FC<IProps> = () => {
    const [t] = useTranslation();
    const jobsData = useJobs();
    const jobsList = useMemo(
        () =>
            jobsData.data?.jobs?.map((purchase) => {
                const inputs: Array<string> = Object.values(purchase.input.sourceFileIds ?? {}).flat();
                const randomInputImage = getRandomElement(inputs);
                const randomOutputImage = getRandomElement(purchase.outputFileIds);
                const imageId = randomOutputImage ?? randomInputImage;
                const isJobCompleted = randomOutputImage !== null;
                const statusIcon = isJobCompleted === true ? <IconCheck color={Color.Green100} /> : <IconClock />;

                return (
                    <JobListItem key={purchase._id}>
                        <H4>{getRelativeTime(t, purchase.created)}</H4>
                        {statusIcon}
                        <JobImage jobId={purchase._id} imageId={imageId} width={200} height={200} canOpen={isJobCompleted} />
                        <SecondaryButtonLink to={HeadbotRoute.ManageJob(purchase._id)}>{t("dashboardPage.manageJob")}</SecondaryButtonLink>
                    </JobListItem>
                );
            }),
        [jobsData.data?.jobs, t]
    );
    const jobs = useMemo(() => {
        return <JobListRoot>{jobsList}</JobListRoot>;
    }, [jobsList]);

    if (jobsData.isLoading) {
        return <Spinner />;
    }

    const hasNoJobs = jobsData.data?.jobs?.length === 0;

    return (
        <Root>
            <ContentRoot>
                <Title>{t("dashboardPage.jobs")}</Title>
                {hasNoJobs && (
                    <div className="mx-auto max-w-7xl px-4 py-8 sm:px-6 lg:px-8">
                        <div className="rounded-md bg-blue-50 p-4 mb-4">
                            <div className="flex">
                                <div className="shrink-0">
                                    <InformationCircleIcon aria-hidden="true" className="size-5 text-blue-400" />
                                </div>
                                <div className="ml-3 flex-1 md:flex md:justify-between">
                                    <p className="text-sm text-blue-700">
                                        You currently have no headjobs. Choose one of the styles below to get started!
                                    </p>
                                </div>
                            </div>
                        </div>
                        <StyleSection />
                    </div>
                )}
                <Content>{jobs}</Content>
            </ContentRoot>
        </Root>
    );
};
